import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : {},
    local_db_version : 2, //increment when making DB change to update users. Data will get from all previous versions, but not future.
  },
  mutations: {
    login(state, data) {
      sessionStorage.setItem('user', JSON.stringify(data));
      state.user = data;
    },
    logout(state) {
      sessionStorage.removeItem('user');
      state.user = {};
    }
  },
  getters: {
    isLoggedIn: state => {
      return typeof state.user.name !== typeof undefined;
    }
  },
  modules: {
  }
})
