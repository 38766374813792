import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
window.axios = require('axios');

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.addEventListener("load", () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("service-worker6.js", {
      updateViaCache: 'none',
    });
  }
});