<template>
  <div id="app">
    <div id="nav">
    </div>
    <AppShell></AppShell>
    <router-view/>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppShell from "./components/AppShell.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppShell,
    AppFooter,
  },
}
</script>>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
