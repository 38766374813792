<template>
  <div class="login">
  <form @submit.prevent="submit">
  <div v-if="!isLoggedIn" class="login">
    <div class="form-group">
        <input type="text" v-model="username">
        <label for="username">Username</label>
    </div>
    <div class="form-group">
        <input type="password" v-model="password">
        <label for="password">Password</label>
    </div>
    <input class="login-button btn btn-primary" type="submit" value="Login">
  </div>
  <div v-else>
  <h3>Logged in as {{this.$store.state.user.name}}</h3>
  <button class="btn btn-primary" type="button" @click="logout">Logout</button>
  </div>
  </form>
  </div>
</template>

<script>
import api from '@/API/APIManager.js';

const API = new api();

export default {
  props: {
  },
  data () {
    return {
      username: '',
      password: '',
    }
  },
  computed: {
    isLoggedIn: function(){
       return this.$store.getters.isLoggedIn;
    }
  },
  methods: {
    async submit() {      
            
      try {
        const result = await API.parts_manager.authLogin(this.username, this.password);
        if ( result === true ){          
          const user = await API.parts_manager.getUserDetails();          
          this.$store.commit('login', user);

        }else {
          console.log(result);
        }
      } catch(error){
        console.log(error);
      }    
    },
    async logout() {
      try {
        const result = await API.parts_manager.authLogout();
        if (result === true) {
          console.log("did logout");
          //Make do more visible.
        } else if(result === false) {
          console.log('no refresh token')
        }
      } finally {
        this.$router.push({ name: 'Home' })
        this.$store.commit('logout');
      }           
    },
  },
  async created() {
    if (localStorage && localStorage.refreshToken) {
      //await API.parts_manager.authRefreshToken(localStorage.refreshToken);
      try{
        const user = await API.parts_manager.getUserDetails();       
        if (user) {
          this.$store.commit('login', user);
        } else {
          this.$store.commit('logout'); 

          throw 'Unable to refresh token';
        }
        
      } catch(error) {

        await API.parts_manager.authLogout();
        this.$router.push({ name: 'Home' })
        this.$store.commit('logout');
      }
      
    }
  }
}
</script>

<style scoped>
.login {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
}

</style>
