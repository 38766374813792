import PartsManagerAPI from "./PartsManagerAPI.js"

class api{
    //return an instance of the imported API object
    constructor() {
        this.api_table = {"parts_manager": PartsManagerAPI};
        //this.running = []; 
        return this.make_proxy(); 
    }

    make_proxy() {
        const handler = {
            get(target,property) {
              return target.get_api(property);
            }
        }          
        return new Proxy(this,handler);
    }

    get_api(search) {
        if (!(search in this.api_table)){
            return; //no api found
        } else {
            return new this.api_table[search];
        }
    }
}


export default api;