import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/returns/',
    component: () => import('../views/ReturnsShell.vue'),
    children: [
      {path:'', name:'Returns', component: () => import('../views/ReturnsTable.vue')},
      {path: 'create', name:'Create New Return', component: () => import('../views/ReturnDetail.vue')},
      {path: ':invoice_id', name:'View Return', component: () => import('../views/ReturnDetail.vue')},
      {path: 'draft/:key', name:'View Draft Return', component: () => import('../views/ReturnDetail.vue')}
    ],
    beforeEnter: (to, from, next) => {
        if (role(["Admin","ProductManager","Branch","SuperBranch","SalesRep"])) {
          return next();
        } else {
          router.push({name: 'Home'});
          return next(false);
        }
    }
  },
]

function role(roles) {
  return ((localStorage.getItem('bearerToken')||localStorage.getItem('refreshToken')) && 
  ( store.state.user && roles.includes(store.state.user.role) ));
}

const router = new VueRouter({
  mode: 'hash',
  base: "/app/", //process.env.BASE_URL,
  routes
})

export default router
