import axios from 'axios'
class PartsManagerAPI {
  constructor () {

    const headers = {
      'X-App-Authorization': 'Bearer VnhRdmdTS3lickdNcFU0blFaTDFXZWtTSmNIcDNoeDVUNzJPMEhaRlRhN0tRWUVWUHhPejI4ald0TUoyRkdkSA=='
    }

    if ( localStorage && localStorage.bearerToken && null != localStorage.bearerToken )
      headers.Authorization = 'Bearer '+localStorage.bearerToken
    
    this.http = axios.create({
      baseURL: 'https://api.panpacific.net.nz',
      timeout: 10000,
      headers: headers
    })
  }

  auth () {
    if ( localStorage && localStorage.bearerToken && null != localStorage.bearerToken )
      return true;
    return false;
  }

  async sleep (seconds) {
    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, seconds * 1000)
    })
  }

  async ping () {
    try {
      // await this.sleep(2)
      const response = await this.http.get('/ping', {
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async authLogin (username, password) {
    try {
      // await this.sleep(2)

      //Create a new request with no headers. Sending old token with correct username and password seems to not work
      var loginRequest = axios.create({
        baseURL: 'https://api.panpacific.net.nz',
        timeout: 10000,
      });

      const response = await loginRequest.post('/auth', {
        username,
        password
      })

      if ( localStorage && response.data.access && response.data.refresh ){
        localStorage.bearerToken = response.data.access
        localStorage.refreshToken = response.data.refresh        

        return true
      }

      return false

    } catch (error) {
      console.error(error)
      throw error
    }
  }
  
  async authLogout () {
    try {
      if ( ! localStorage || ! localStorage.refreshToken ){
        if ( localStorage && localStorage.bearerToken )
          localStorage.removeItem('bearerToken')
        return false
      }
      // await this.sleep(2)
      const response = await this.http.delete('/auth', {
        data: {
          refreshToken: localStorage.refreshToken
        }
      })

      if ( response.data ){
        return true
      }

      return false

    } catch (error) {
      console.error(error)
      
    } finally {
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userRole');
    }
  }

  async getUserDetails () {
    if (!this.auth()) return false;
    try {
      if (localStorage && localStorage.getItem('bearerToken')) {
        if (sessionStorage) {
          const response = await this.http.get('/users');
          return response.data;
        }
      }
    } catch(error) {
      console.log(error);
      throw error;
    }
  }

  async authRefreshToken (refreshToken) {
    try {
      // await this.sleep(2)
      const response = await this.http.get('/auth/refresh_token', {
        params: {
          refreshToken
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async createRma (rma) {
    if (!this.auth()) return false;
    try {
      // await this.sleep(2)
      const response = await this.http.post('/rmas', rma, {
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateRma (number, rma) {
    if (!this.auth()) return false;
    try {
      // await this.sleep(2)
      const response = await this.http.put('/rmas/' + number, rma, {
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateReturnStatus (returnId, status) {
    if (!this.auth()) return false;
    try {
      const response = await this.http.put('/returns/' + returnId + '/status/' + status, {
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getRma (number) {
    if (!this.auth()) return false;
    try {
      // await this.sleep(2)
      const response = await this.http.get('/rmas/' + number, {
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getRmas (number, date, customer, accountCode, branch, status) {
    if (!this.auth()) return false;
    try {
      // await this.sleep(2)
      const response = await this.http.get('/rmas', {
        params: {
          number,
          date,
          customer,
          accountCode,
          branch,
          status
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getParts (id, barcode) {
    if (!this.auth()) return false;
    try {
      // await this.sleep(2)
      const response = await this.http.get('/parts', {
        params: {
          id,
          barcode
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getPart (id) {
    if (!this.auth()) return false;
    try {
      // await this.sleep(2)
      const response = await this.http.get('/parts/' + id, {
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getReturns(params) {
    if (!this.auth()) return false;
    try {
      const response = await this.http.get('/returns', {
        params,
      })
      return response.data;

    } catch (error) {
      console.log(error);
      throw error;

    }
  }

  async getAccountCodes(q) {
    if (!this.auth()) return false;
    try {
      const response = await this.http.get('/accounts', {
        params: {
          q
        }
      })
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getAccountEmail(accountCode) {
    if (!this.auth()) return false;
    try {
      //await this.sleep(2);
      const response = await this.http.get('/accounts/'+accountCode+'/email', {});
      return response;
        
      }catch(error) {
        console.log(error);
        throw error;
      }
  }


  async getReturnParts(q, accountCode) {
    if (!this.auth()) return false;
    try {
      const response = await this.http.get('/returns/parts', {
        params: {
          q,
          accountCode,
        }
      });

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getInvoices(q,accountCode,part) {
    if (!this.auth()) return false;
    try {
      const response = await this.http.get('/returns/invoices', {
        params: {
          q, 
          accountCode,
          part,
        }
      })
      return response
    } catch(error) {
      console.log(error);
      throw error;

    }
  }

  async getReturnItems(returnId) {
    if (!this.auth()) return false;
    try {
      const response = await this.http.get('/returns/' + returnId + '/items', {});

      return response;
    } catch(error) {
      console.log(error);
      throw error;
    }
  }

  async createOCR(accountCode, email, customerRef, items){
    if (!this.auth()) return false;

    try {
      const response = await this.http.post('/returns', {
          accountCode,
          email,
          customerRef,
          items
      });
      return response.data

    } catch(error) {
      //Should probably log errors here. 
      console.log(error);
      throw error;
    }
  }

  async getBranchList(){
    if (!this.auth()) return false;

    try {
      const response = await this.http.get('returns/branches', {
      });

      return response;
    } catch(error) {
      console.log(error);
      throw error;
    }
  }

  async getReturnReasons() {
    if (!this.auth()) return false;

    try {
      const response = await this.http.get('return_reasons', {
      });

      return response;
    } catch(error) {
      console.log(error);
      throw error;
    }
    
  }
}

export default PartsManagerAPI
