<template>
    <div class="app-shell">
        <!-- <aside v-show="!online" class="notification-top offline-alert" id="offline-banner">
            Offline
        </aside>      -->
        <aside v-show="!installed" class="notification-top" id="install-banner">
            <button id="install_button" @click="installer()">Install Now</button>  
        </aside>
        <header>
        <section v-if="isLoggedIn" class="header-top">
            <a href="javascript:void(0);" onclick="document.getElementById('sidebar_left').classList.toggle('open')" ><img
                    src="../assets/svg/menu.svg" alt="Main Menu" title="Menu"></a>
            <a href="javascript:void(0);" onclick="document.getElementById('header_search').classList.toggle('open');document.querySelector('#header_search.open input').focus()"><img src="../assets/svg/magnify.svg" alt="Search"></a>
            <a class="logo" href="/app"><img src="../assets/images/logo.png" alt="logo"></a>
            <a href="javascript:void(0);"><img src="../assets/svg/account.svg" alt="User Menu" onclick="document.getElementById('sidebar_login').classList.toggle('open')" title="Account"></a>
            <a href="javascript:void(0);" onclick="document.getElementById('sidebar_right').classList.toggle('open')"><img
                    src="../assets/svg/cart.svg" alt="Cart"></a>
        </section>
        <section v-else>
            <a class="logo" href="/app"><img src="../assets/images/logo.png" alt="logo"></a>
        </section>
        <form id="header_search" class="header-search" action="#" method="GET">
            <input name="search" autocomplete="search" id="header-search-input" type="search">
            <button type="submit">Search</button>
        </form>
        <!-- <nav class="navigation-main">
            <ul>
                <li>
                    <a href="/" onclick="return false;">Home</a>
                </li>
                <li>
                    <a href="/Category/Category1" onclick="return false;">Category1</a>
                    <ul>
                        <li>
                            <a href="/SubCategory/SubCategory1" onclick="return false;">SubCategory1</a>
                            <ul>
                                <li><a href="/SubCategory/SubCategory2">SubCategory2</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav> -->
    </header>
    <aside class="sidebar left" id="sidebar_left">
        <div class="backdrop" onclick="document.getElementById('sidebar_left').classList.toggle('open')"></div>
        <button class="close-button" href="#"
            onclick="document.getElementById('sidebar_left').classList.toggle('open')"><img src="../assets/svg/close.svg"
                alt="Close Sidebar"></button>

        <div class="content">
            <h2>Actions</h2>
            <a v-if="this.$store.getters.isLoggedIn" class="btn" href="#/returns" onclick="document.getElementById('sidebar_left').classList.remove('open')">Credit Returns</a>
        </div>
    </aside>

    <aside class="sidebar right" id="sidebar_right">
        <div class="backdrop" onclick="document.getElementById('sidebar_right').classList.toggle('open')"></div>
        <button class="close-button" href="#"
            onclick="document.getElementById('sidebar_right').classList.toggle('open')"><img src="../assets/svg/close.svg"
                alt="Close Sidebar"></button>

            <div class="content"></div>
    </aside>
    
    <aside class="sidebar right" id="sidebar_login">
        <div class="backdrop" onclick="document.getElementById('sidebar_login').classList.toggle('open')"></div>
        <button class="close-button" href="#"
            onclick="document.getElementById('sidebar_login').classList.toggle('open')"><img src="../assets/svg/close.svg"
                alt="Close Sidebar"></button>
            <div class="content">
                <Login></Login>             
            </div>
    </aside>

    </div>
</template>
<script>
import Login from "./Login.vue";

export default {
    components:{
        Login
    },
    data() {
        return {
            online:true,
            installed:false,
        }
    },
    computed: {
        isLoggedIn: function(){
            return this.$store.getters.isLoggedIn;
        }
    },
    methods: {
        handleNetworkChange:function() {
            if (navigator.onLine) {                    
                return fetch("https://api.panpacific.net.nz/ping", {
                    method: 'GET'
                }).then(()=>{
                    this.online=true;
            }).catch(()=> {
                this.online = false;
            });            
        } else {
            this.online = false;
        }

        },
    },
    created() {
        if ((window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://')) {
           this.installed = true;                               
        } else {
            this.installed = false;
        }

        //Capture the initial install prompt and reuse it when the button is pressed
        let deferredPrompt; 
        window.addEventListener('beforeinstallprompt', (e)=> {
            deferredPrompt = e;
        });
        this.installer = ()=> {            
            if (deferredPrompt) {
                 deferredPrompt.prompt();
                deferredPrompt.userChoice.then((choice) => {
                    if (choice.outcome === 'accepted') {
                        this.installed = true;       
                    }
                })
            }           
        }        

        this.handleNetworkChange();
        window.addEventListener("online", this.handleNetworkChange);
        window.addEventListener("offline",this.handleNetworkChange);

    }
}
</script>

<style scoped>
@import "../css/styles.css";
</style>