<template>
    <footer>
        <nav class="navigation-footer">
        </nav>
        <center>  
            <h2>&copy; Pan Pacific Auto Electronics Ltd</h2>
            <p class="mt-1">
                This site is not available to the public<br>
                Please visit <a href="https://www.panpacific.net.nz/">panpacific.net.nz</a>
            </p>
            <hr class="mt-1">
            <p class="mt-1">
                developed by <a href="https://www.warp.co.nz">Warp Speed Computers</a>
            </p>
        </center>
    </footer>
</template>
<script>
export default {
    
}
</script>

<style scoped>

</style>