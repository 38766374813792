<template>
  <div class="home">
      <div class="login">
      <form @submit.prevent="submit">
          <div class="login">
            <div class="form-group">
                <input type="text" v-model="username">
                <label for="username">Username</label>
            </div>
            <div class="form-group">
                <input type="password" v-model="password">
                <label for="password">Password</label>
            </div>
            <input class="login-button btn btn-primary" type="submit" value="Login">
          </div>
      </form>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import api from '@/API/APIManager.js';
const API = new api();

export default {
  name: 'Home',
  components: {
    
  },
  data: function(){
    return {
      username:"",
      password:"",
    }    
  },
  methods : {
     async submit() {    
      try {
        const result = await API.parts_manager.authLogin(this.username, this.password);
        if ( result === true ){          
          const user = await API.parts_manager.getUserDetails();          
          this.$store.commit('login', user);
          this.$router.push({ name: 'Returns' })

        }else {
          console.log(result);
        }
      } catch(error){
        console.log(error);
      }    
    },
  },
  async created() {
    if (localStorage && localStorage.refreshToken) {
      //await API.parts_manager.authRefreshToken(localStorage.refreshToken);
      try{
        const user = await API.parts_manager.getUserDetails();       
        if (user) {
          this.$store.commit('login', user);
          this.$router.push({ name: 'Returns' })
        } else {
          this.$store.commit('logout'); 

          throw 'Unable to refresh token';
        }
        
      } catch(error) {
        await API.parts_manager.authLogout();
        this.$router.push({ name: 'Home' })
        this.$store.commit('logout');

      }
      
    }
  }
}
</script>


<style scoped>
.home {
}

.login {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    margin:auto;
    margin-top: 2em;
    max-width: min(400px,95%);
}
.login p {
  font-weight: bold;
}

</style>